<template>
  <div id="success-payment-page">
      <NavPackage/>
      <div class="justify-content-center align-items-center d-flex flex-column mt-100">
        <img src="@/assets/images/failed-payment.svg" class="mb-4" style="height: 128px; width: 128px" alt="">
        <span class="title-sp-payment mb-4">Payment failed</span>
        <span class="sub-title-sp-payment">Unfortunately, we’re unable to process your payment.</span>
        <span class="sub-title-sp-payment mb-4">Please try again or contact your payment gateway provider.</span>
        <span @click="goToPackages()" class="btn-sp">Try Again</span>
      </div>
  </div>
</template>

<script>
import NavPackage from '../../../components/nav-packages.vue'
export default {
  name: 'PaymentFailed',
  components: {
      NavPackage
  },
  mounted() {
    //   this.$ga.page('payment/failed')
      this.$ga.event('payment-failed-page', 'actionx', 'labelx', 1)
  },
  methods: {
      goToPackages(){
          this.$router.push('/packages')
      }
  }
}
</script>

<style scoped>

</style>